<template>
  <div>
    <table-component :items="items" :busy="busy" :fields="fields" />
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
export default {
  components: { TableComponent },
  data() {
    return {
      items: [],
      busy: false,
      fields: [
      {
          key: "user",
          label: "Enrollee Name",
          formatter: (val) => {
            if (val) {
              return val.first_name + " " + val.last_name;
            } else {
              return "No user found";
            }
          },
        },
        {
          key: "user",
          label: "Enrollee Name",
          formatter: (val) => {
            if (val) {
              return val.first_name + " " + val.last_name;
            } else {
              return "No user found";
            }
          },
        },
        {
          key: "user.address",
          label: "Address",
          formatter: (val) => {
            if (val) {
              return val;
            } else {
              return "No user found";
            }
          },
        },
        {
          key: "user.unique_id",
          label: "Unique ID",
          formatter: (val) => {
            if (val) {
              return val;
            } else {
              return "No user found";
            }
          },
        },
        {
          key: "plan.name",
          label: "Plan",
        },
      ],
    };
  },

  methods: {
    getEnrollees() {
      this.busy = true;
      this.$request
        .get("/hospitals/enrollees/all")
        .then((res) => {
          console.log(res);
          let resPayload = res.data.data;
          this.items = resPayload;
          this.busy = false;
        })
        .catch((err) => {
          this.busy = false;
          console.log(err);
        });
    },
  },

  beforeMount() {
    this.getEnrollees();
  },
};
</script>

<style></style>
