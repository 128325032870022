<template>
  <div>
    <div class="tw-flex tw-justify-between tw-items-center">
      <UtilsBaseCardTab class="">
        <UtilsCardTab title="Due Invoices">
          <div class="tw-mt-3">
            <table-component :items="dueInvoices" :fields="unsubmittedFields" :busy="busy" />
            <div>
              <invoices-summary :dueClaims="dueInvoices" @reloadData="reloadData"/>
            </div>
          </div>
        </UtilsCardTab>
        <UtilsCardTab title="Submitted Invoices">
          <div class="tw-mt-3">
            <table-component :items="items" :fields="fields" :busy="busy" />
          </div>
        </UtilsCardTab>
      </UtilsBaseCardTab>
    </div>
  </div>
</template>

<script>
import UtilsBaseCardTab from "@/components/Utils/BaseCardTab.vue";
import UtilsCardTab from "@/components/Utils/CardTab.vue";
import TableComponent from "@/components/TableComponent.vue";
import InvoicesSummary from '@/components/Invoices/InvoicesSummary.vue';
export default {
  components: { TableComponent, UtilsBaseCardTab, UtilsCardTab, InvoicesSummary },
  data() {
    return {
      items: [],
      filtered: [],
      dueInvoices: [],
      busy: false,
      fields: [
        {
          key: "claim_period",
          label: "Claims Period",
        },
        {
          key: "no_of_claims",
          label: "Number of Claims",
          //   formatter: (val) => {
          //     if (val) {
          //       return val.first_name + " " + val.last_name;
          //     } else {
          //       return "No user found";
          //     }
          //   },
        },
        {
          key: "total_amount",
          label: "Amount",
          formatter: (item) => {
            return item ? Number(item).toLocaleString('en-US', { style: 'currency', currency:  'NGN'}) : "NGN 0.00";
          },
          //   formatter: (val) => {
          //     if (val) {
          //       return val;
          //     } else {
          //       return "No user found";
          //     }
          //   },
        },
        {
          key: "remote_status",
          label: "Remote Status",
          //   formatter: (val) => {
          //     if (val) {
          //       return val;
          //     } else {
          //       return "No user found";
          //     }
          //   },
        },
        {
          key: "created_at",
          label: "Date",
        },
        {
          key: "payment_status",
          label: "Payment Status",
             formatter: (val) => {
              if (val  === '0') {
                return 'pending';
              } else {
                return "paid";
              }
            },
          
        },
      ],
      unsubmittedFields: [
        {
          key: "claimEnrollee",
          label: "Name",
        },
        {
          key: "claimEnrolleeAddress",
          label: "Address",
          //   formatter: (val) => {
          //     if (val) {
          //       return val.first_name + " " + val.last_name;
          //     } else {
          //       return "No user found";
          //     }
          //   },
        },
        {
          key: "claimEnrolleeUnique",
          label: "Unique No",
          //   formatter: (val) => {
          //     if (val) {
          //       return val.first_name + " " + val.last_name;
          //     } else {
          //       return "No user found";
          //     }
          //   },
        },
        {
          key: "total_amount",
          label: "Amount",
          formatter: (item) => {
            return item ? Number(item).toLocaleString('en-US', { style: 'currency', currency:  'NGN'}) : "NGN 0.00";
          },
          //   formatter: (val) => {
          //     if (val) {
          //       return val;
          //     } else {
          //       return "No user found";
          //     }
          //   },
        },
        {
          key: "claim_status",
          label: "Status",
          formatter: (val) => {
              if (val  === '0') {
                return 'pending';
              } else {
                return "submitted";
              }
            },
          //   formatter: (val) => {
          //     if (val) {
          //       return val;
          //     } else {
          //       return "No user found";
          //     }
          //   },
        },
        {
          key: "created_at",
          label: "Date",
        },
        // {
        //   key: "payment_status",
        //   label: "Payment Status",
        //      formatter: (val) => {
        //       if (val  === '0') {
        //         return 'pending';
        //       } else {
        //         return "paid";
        //       }
        //     },
          
        // },
      ],
    };
  },

  methods: {

    getInvoices() {
      this.busy = true;
      this.$request
        .get("/hospitals/invoices")
        .then((res) => {
          console.log(res, 'submitted');
          let resPayload = res.data.data;
          this.items = resPayload;
          this.busy = false;
        })
        .catch((err) => {
          this.busy = false;
          console.log(err);
        });
    },

    getUnsubmittedClaims() {
      this.busy = true;
      this.$request
        .get("/hospitals/claims")
        .then((res) => {
          let resPayload = res.data.data;
          this.dueInvoices = resPayload.filter((elem) => elem.is_submitted !== "1");
          this.busy = false;
        })
        .catch((err) => {
          this.busy = false;
          console.log(err);
        });
    },

    reloadData(){
      this.getUnsubmittedClaims();
    this.getInvoices()
    }

    
  },

  beforeMount() {
    this.getUnsubmittedClaims();
    this.getInvoices()
  },
};
</script>

<style></style>
