<template>
    <div>
        <table-component :items="claims" :fields="fields" :busy="busy">
            <template #button>
                <button class="veo-btn veo-primary-btn" @click="$router.push('/dashboard/claim/create')">
                    Create Claim
                </button>
            </template>
            </table-component>
    </div>
  </template>
  
  <script>
  import TableComponent from "@/components/TableComponent.vue";
  export default {
    components: { TableComponent },
    data() {
      return {
        busy: false,
        claims: [],
        fields: [
          {
            key: "claimEnrollee",
            label: "Name",
          },
          {
            key: "claimEnrolleeAddress",
            label: "Address",
            //   formatter: (val) => {
            //     if (val) {
            //       return val.first_name + " " + val.last_name;
            //     } else {
            //       return "No user found";
            //     }
            //   },
          },
          {
            key: "claimEnrolleeUnique",
            label: "Unique No",
            //   formatter: (val) => {
            //     if (val) {
            //       return val.first_name + " " + val.last_name;
            //     } else {
            //       return "No user found";
            //     }
            //   },
          },
          {
            key: "total_amount",
            label: "Amount",
            formatter: (item) => {
              return item ? Number(item).toLocaleString('en-US', { style: 'currency', currency:  'NGN'}) : "NGN 0.00";
            },
            //   formatter: (val) => {
            //     if (val) {
            //       return val;
            //     } else {
            //       return "No user found";
            //     }
            //   },
          },
          {
            key: "claim_status",
            label: "Status",
            formatter: (val) => {
                if (val  === '0') {
                  return 'pending';
                } else {
                  return "submitted";
                }
              },
            //   formatter: (val) => {
            //     if (val) {
            //       return val;
            //     } else {
            //       return "No user found";
            //     }
            //   },
          },
          {
            key: "created_at",
            label: "Date",
          },
          // {
          //   key: "payment_status",
          //   label: "Payment Status",
          //      formatter: (val) => {
          //       if (val  === '0') {
          //         return 'pending';
          //       } else {
          //         return "paid";
          //       }
          //     },
            
          // },
        ],
      };
    },
  
    methods: {
  
      getClaims() {
        this.busy = true;
        this.$request
          .get("/hospitals/claims")
          .then((res) => {
            console.log(res,"unsubmitted");
            let resPayload = res.data.data;
            this.claims = resPayload
            this.busy = false;
          })
          .catch((err) => {
            this.busy = false;
            console.log(err);
          });
      },
  
      
    },
  
    beforeMount() {
      this.getClaims();
    },
  };
  </script>
  
  <style></style>
  