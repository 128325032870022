<template>
  <div>
    <section class="mt-4 tw-shadow-md" v-if="dueClaims.length > 0">
      <div class="tw-bg-primary px-4 py-3">
        <h5 class="text-white tw-mb-0">Invoice Summary</h5>
      </div>
      <div class="other--tables bg-white rounded-lg p-4">
        <div class="row">
          <span class="col-md-3 m-0 p-0" style="font-size: 13px"
            >No. of Claims</span
          >
          <span class="col-md-8 m-0 p-0" style="font-size: 13px">
            {{ dueClaims.length }}
          </span>
        </div>

        <hr />

        <div class="row my-3">
          <span class="col-md-3 m-0 p-0" style="font-size: 13px"
            >Invoice Total</span
          >
          <span class="col-md-8 m-0 p-0" style="font-size: 13px">
            {{
              Number(total_amount).toLocaleString("en-US", {
                style: "currency",
                currency: "NGN",
              })
            }}
          </span>
        </div>

        <hr />

        <div class="row">
          <span class="col-md-3 m-0 p-0" style="font-size: 13px"
            >Period Captured</span
          >
          <span class="col-md-8 m-0 p-0" style="font-size: 13px">
            {{ date }}
          </span>
        </div>

        <hr />

        <div class="text-right">
          <button class="veo-btn veo-primary-btn" @click="submitInvoice">
            <span>Submit Invoice</span>
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { timeStamp } from "@/plugins/filter";
import { mapState } from "vuex";
import config from "@/config/api";
export default {
  props: {
    dueClaims: {
      type: Array,
    },
  },
  data() {
    return {
      config,
      timeStamp,
    };
  },
  methods: {
    submitInvoice() {
      let formData = new FormData();
      formData.append("hospital_id", this.hospital.id),
        formData.append("claim_period", this.date),
        formData.append("no_of_claims", this.dueClaims.length),
        formData.append("total_amount", String(this.total_amount)),
        formData.append("hospital_name", this.hospital.name),
        (this.busy = true);
      this.$request
        .post("/hospitals/invoices/add", formData)
        .then((res) => {
          console.log(res, "submitted");
          this.$toastify({
            text: "Invoice Submitted Succesfully",
            className: "info",
            style: {
              background: "green",
              fontSize: "13px",
              borderRadius: "5px",
            },
          }).showToast();
          this.$emit('reloadData')
          this.busy = false;    
        })
        .catch((err) => {
          this.busy = false;
          console.log(err);
        });
    },
  },
  beforeMount() {},
  computed: {
    // invoices() {
    //   let data = this.dueClaims;
    //   let values = data.filter((elem) => elem.is_submitted !== "1");
    //   return values;
    // },

    ...mapState("auth", {
      hospital: (state) => state.user,
    }),

    date() {
      // ✅ Get Max date
      const maxDate = new Date(
        Math.max(
          ...this.dueClaims.map((element) => {
            return new Date(element.created_at);
          })
        )
      );

      // 👇️ Thu Jul 17 2022
      console.log(maxDate);

      // ✅ Get Min date
      const minDate = new Date(
        Math.min(
          ...this.dueClaims.map((element) => {
            return new Date(element.created_at);
          })
        )
      );

      // 👇️ Sat Sep 24 2022
      console.log(minDate);
      return timeStamp(minDate) + " to " + timeStamp(maxDate);
    },
    total_amount() {
      let no_nan = this.dueClaims.filter((item) => !isNaN(item.total_amount));
      console.log(no_nan);
      let total_amount = no_nan.reduce((accumulator, item) => {
        return accumulator + Number(item.total_amount);
      }, 0);
      return total_amount;
    },
  },
};
</script>
