<template>
  <div>
    <el-dialog
      title="Verify Enrollee"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="30%"
    >
      <div>
        <h6 class="tw-text-primary tw-mb-4">
          Enter Enrollee ID to verify Enrollee
        </h6>

        <div class="tw-mb-3">
            <p class="tw-text-red-600 tw-text-[12px] tw-block tw-break-all" v-html="error"></p>
        </div>

        <validation-observer v-slot="{ invalid, handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <validation-provider
              name="enrollee id"
              rules="required"
              v-slot="{ errors }"
            >
              <input
                class=""
                type="text"
                v-model="payload.unique_id"
                placeholder="Enter Unique ID"
              />
              <span class="tw-text-xs tw-text-red-600" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
            <div class="tw-mt-4 tw-text-center">
              <button
                class="veo-btn veo-primary-btn tw-px-8"
                v-bind:disabled="invalid"
                :class="{ 'tw-bg-gray-400': invalid || loading }"
              >
                <span>Verify</span>
              </button>
            </div>
          </form>
        </validation-observer>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    visible: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    error: {
        type: String
    }
  },
  data() {
    return {
      payload: {
        unique_id: "",
      },
      notFound: false,
      verified: false,
      verified_status: false,
      verifying: false,
      dialogVisible: false,
    };
  },
  methods: {
    handleClose() {
      this.$router.go(-1);
    },

    ...mapActions("claims", ["checkVerified"]),

    continue() {
      this.$emit("verified");
    },

    onSubmit(){
        this.$emit('verifyUser', JSON.stringify(this.payload))
    }
  },

  watch: {
    visible: {
      handler(val) {
        this.dialogVisible = val;
      },
      immediate: true,
    },
  },
};
</script>
