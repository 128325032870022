<template>
  <div>
    <div class="tw-flex tw-justify-between tw-items-center">
      <UtilsBaseCardTab class="">
        <UtilsCardTab title="Drug Prices">
          <div class="tw-mt-3">
            <table-component :items="drugs" :fields="drugFields" :busy="busy" />
          </div>
        </UtilsCardTab>
        <UtilsCardTab title="Service Prices">
          <div class="tw-mt-3">
            <table-component
              :items="services"
              :fields="serviceFields"
              :busy="busy"
            />
          </div>
        </UtilsCardTab>
      </UtilsBaseCardTab>
    </div>
  </div>
</template>

<script>
import UtilsBaseCardTab from "@/components/Utils/BaseCardTab.vue";
import UtilsCardTab from "@/components/Utils/CardTab.vue";
import TableComponent from "@/components/TableComponent.vue";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    TableComponent,
    UtilsBaseCardTab,
    UtilsCardTab,
  },
  data() {
    return {
      items: [],
      busy: false,
      fields: [
        {
          key: "claim_period",
          label: "Claims Period",
        },
        {
          key: "no_of_claims",
          label: "Number of Claims",
          //   formatter: (val) => {
          //     if (val) {
          //       return val.first_name + " " + val.last_name;
          //     } else {
          //       return "No user found";
          //     }
          //   },
        },
        {
          key: "total_amount",
          label: "Amount",
          formatter: (item) => {
            return item
              ? Number(item).toLocaleString("en-US", {
                  style: "currency",
                  currency: "NGN",
                })
              : "NGN 0.00";
          },
          //   formatter: (val) => {
          //     if (val) {
          //       return val;
          //     } else {
          //       return "No user found";
          //     }
          //   },
        },
        {
          key: "remote_status",
          label: "Remote Status",
          //   formatter: (val) => {
          //     if (val) {
          //       return val;
          //     } else {
          //       return "No user found";
          //     }
          //   },
        },
        {
          key: "created_at",
          label: "Date",
        },
        {
          key: "payment_status",
          label: "Payment Status",
          formatter: (val) => {
            if (val === "0") {
              return "pending";
            } else {
              return "paid";
            }
          },
        },
      ],

      serviceFields: [
        {
          key: "service.name",
          label: "Service Name",
        },
        {
          key: "price",
          label: "Price",
          formatter: (item) => {
            return item
              ? Number(item).toLocaleString("en-US", {
                  style: "currency",
                  currency: "NGN",
                })
              : "NGN 0.00";
          },
        },
        {
          key: "created_at",
          label: "Date Created",
        },
      ],
      drugFields: [
        {
          key: "drug.name",
          label: "Drug Name",
        },
        {
          key: "price",
          label: "Price",
          formatter: (item) => {
            return item
              ? Number(item).toLocaleString("en-US", {
                  style: "currency",
                  currency: "NGN",
                })
              : "NGN 0.00";
          },
        },
        {
          key: "created_at",
          label: "Date Created",
        },
      ],
    };
  },

  methods: {
    ...mapActions("claims", ["setDrugsPrices", "setServicesPrices"]),

    getInvoices() {
      this.busy = true;
      this.$request
        .get("/hospitals/invoices")
        .then((res) => {
          console.log(res, "submitted");
          let resPayload = res.data.data;
          this.items = resPayload;
          this.busy = false;
        })
        .catch((err) => {
          this.busy = false;
          console.log(err);
        });
    },

    getUnsubmittedClaims() {
      this.busy = true;
      this.$request
        .get("/hospitals/claims")
        .then((res) => {
          let resPayload = res.data.data;
          this.dueInvoices = resPayload.filter(
            (elem) => elem.is_submitted !== "1"
          );
          this.busy = false;
        })
        .catch((err) => {
          this.busy = false;
          console.log(err);
        });
    },
  },

  beforeMount() {
    this.setDrugsPrices();
    this.setServicesPrices();
  },

  computed: {
    ...mapState("claims", {
      services: (state) => state.service_prices,
      drugPrice: (state) => state.drug_price,
      drugs: (state) => state.drug_prices,
      servicePrice: (state) => state.service_price,
    }),
  },
};
</script>

<style></style>
