import Vue from 'vue'
import Vuex from 'vuex'


import createPersistedState from 'vuex-persistedstate';

import auth from './modules/auth'
import drawer from './drawer'
import claims from './modules/claims'


Vue.use(Vuex)

export default new Vuex.Store({
    strict: true,
    plugins: [createPersistedState()],
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        drawer,
        claims,
    }
})