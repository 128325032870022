<template>
    <div>
      <h4 class="header-text">Plan Details</h4>
      <p class="tag-info">View user plan details</p>
      <div>
        {{ info }}
      </div>
      <div class="info-container mt-3" v-for="item in info.plan" :key="item.id">
        <div class="info-card">
          <span class="info-card-header">
            <span class="info-card-header-text">Plan Name</span>
            <span class="info-card-icon"> <i-icon icon="ph:user-circle" /> </span>
          </span>
          <span class="info-card-data"> {{ info.first_name + ' ' + info.last_name +  ' ' + (info.other_name || '' )  }} </span>
        </div>
        <div class="info-card">
          <span class="info-card-header">
            <span class="info-card-header-text">Amount</span>
            <span class="info-card-icon"> <i-icon icon="carbon:ticket" /> </span>
          </span>
          <span class="info-card-data"> {{ info.unique_id }} </span>
        </div>
        <div class="info-card">
          <span class="info-card-header">
            <span class="info-card-header-text">Duration</span>
            <span class="info-card-icon"> <i-icon icon="uiw:date" /> </span>
          </span>
          <span class="info-card-data"> {{ info.dob || "No data" }} </span>
        </div>
        <div class="info-card">
          <span class="info-card-header">
            <span class="info-card-header-text">Status</span>
            <span class="info-card-icon"> <i-icon icon="pajamas:location" /> </span>
          </span>
          <span class="info-card-data"> {{ info.address }} </span>
        </div>
        <div class="info-card">
          <span class="info-card-header">
            <span class="info-card-header-text">Date of Payment</span>
            <span class="info-card-icon"> <i-icon icon="uiw:date" /> </span>
          </span>
          <span class="info-card-data"> {{ info.email }} </span>
        </div>
        <div class="info-card">
          <span class="info-card-header">
            <span class="info-card-header-text">Phone Number</span>
            <span class="info-card-icon"> <i-icon icon="basil:phone-in-outline" /> </span>
          </span>
          <span class="info-card-data"> {{ info.phone_number }} </span>
        </div>
      </div>
      <!-- {{ info }} -->
    </div>
  </template>
  
  <script>
  // import { mapState } from "vuex";
  export default {
    props: {
    info: {
      type: Object
    }
  },
    computed: {
      // ...mapState("claims", {
      //   info: (state) => state.verifiedUser,
      // }),
    },
  };
  </script>
  
  <style>
  .info-container {
    display: grid;
    display: -ms-grid;
    display: -moz-grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .info-card {
    background: #fff;
    padding: 18px;
    border-radius: 15px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.125);
    display: flex;
    display: inline-flex;
    display: -webkit-flex;
    flex-direction: column;
    gap: 6px;
  }
  .tag-info {
      font-size: 14px;
      color: var(--gray-600);
      font-weight: 400;
  }
  .header-text {
       font-weight: 600;
  }
  .info-card-header-text {
      font-size: 15px;
      font-weight: 600;
  }
  .info-card-data {
      font-size: 14px;
      color: var(--gray-600);
  }
  .info-card-header {
      display: flex;
      display: inline-flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: space-between;
  }
  .info-card-header .info-card-icon {
      color: var(--main-color);
  }
  </style>
  