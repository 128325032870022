<template>
  <div class="mt-5">
    <add-claims-header :activeTab="activeTab" :headerItems="headerItems" />
    <div class="mt-3" v-if="verifiedUser">
      <div v-if="activeTab === 1">
        <h5 class="form-header mb-2">Enrollee Information</h5>
        <div class="d-flex" style="gap: 20px">
          <div class="w-50">
            <label for="">Enrollee ID</label>
            <el-input
              readonly
              disabled
              placeholder=""
              v-model="verifiedUser.unique_id"
            ></el-input>
          </div>
          <div class="w-100">
            <label for="">Enrollee Name</label>
            <el-input
              readonly
              disabled
              placeholder=""
              v-model="userName"
            ></el-input>
          </div>
          <div class="w-50">
            <label for="">Gender</label>
            <el-select placeholder="" v-model="gender" name="gender">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="d-flex mt-3" style="gap: 20px">
          <div class="w-100">
            <label for="">Company</label>
            <el-input placeholder="" v-model="company"></el-input>
          </div>
          <div class="w-50">
            <label for="">Care Type</label>
            <el-select placeholder="" v-model="care_type" name="gender">
              <el-option
                v-for="item in careTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="w-50">
            <label for="">Verification Code</label>
            <el-input
              placeholder=""
              v-model="treatment_verification_code"
            ></el-input>
          </div>
        </div>
        <h5 class="mt-4 form-header mb-2">Provider Information</h5>
        <div class="d-flex" style="gap: 20px">
          <div class="w-50">
            <label for="">Doctor</label>
            <el-input placeholder="" v-model="doctor_name"></el-input>
          </div>
          <div class="w-100">
            <label for="">Area of Specialty</label>
            <el-input placeholder="" v-model="area_of_speciality"></el-input>
          </div>
          <div class="w-50">
            <label for="">Date Signed</label>
            <el-input
              placeholder=""
              type="date"
              v-model="date_of_service"
            ></el-input>
          </div>
        </div>
      </div>
      <div v-if="activeTab === 2">
        <h5 class="form-header mb-2">Treatment Details</h5>
        <div class="d-flex" style="gap: 20px">
          <div class="w-50">
            <label for="">Date of Admission</label>
            <el-input
              v-model="date_of_admission"
              placeholder=""
              name="id"
              type="date"
            ></el-input>
          </div>
          <div class="w-50">
            <label for="">Duration of treatment (days)</label>
            <el-input placeholder="" v-model="duration_of_treatment"></el-input>
          </div>
          <div class="w-50">
            <label for="">Date of Discharge</label>
            <el-input
              v-model="date_of_discharge"
              placeholder=""
              name="id"
              type="date"
            ></el-input>
          </div>
        </div>
        <div class="d-flex mt-3" style="gap: 20px">
          <div class="w-100">
            <label for="">Treatment Code</label>
            <el-input placeholder="" v-model="code"></el-input>
          </div>
          <div class="w-100">
            <label for="">Referral Code</label>
            <el-input placeholder="" v-model="referral_code"></el-input>
          </div>
        </div>
        <div class="d-flex mt-3" style="gap: 20px">
          <div class="w-50">
            <label for="">Diagnosis</label>
            <el-input type="textarea" v-model="diagnosis" rows="5"></el-input>
          </div>
          <div class="w-50">
            <label for="">Treatment Given</label>
            <el-input
              type="textarea"
              v-model="treatment_given"
              rows="5"
            ></el-input>
          </div>
        </div>
      </div>
      <div v-if="activeTab === 3">
        <h5 class="form-header mb-2">Drugs Information</h5>
        <div
          class="d-flex align-items-center mt-2"
          v-for="(item, index) in drugObject"
          :key="index"
          style="gap: 20px"
        >
          <div class="w-100">
            <!-- <div>
                {{ drugs }}
              </div> -->
            <label for="">Drug Name</label>
            <el-select
              v-model="item.drug"
              filterable
              placeholder="Enter Drug"
              @change="getSingleDrug($event, index)"
            >
              <el-option
                v-for="(item, idx) in drugs"
                :key="idx"
                :label="item.drug.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="w-25">
            <label for="">Unit</label>
            <el-input placeholder="" v-model="item.unit"></el-input>
          </div>
          <div class="w-25">
            <label for="">Rate</label>
            <el-input
              readonly
              disabled
              placeholder=""
              v-model="item.rate"
            ></el-input>
          </div>
          <div class="w-25">
            <label for="">Amount</label>
            <el-input
              readonly
              disabled
              placeholder=""
              v-model="item.amount"
            ></el-input>
          </div>
          <div class="">
            <span role="button" @click="removeDrug($event)">
              <i-icon icon="fluent:delete-24-filled" class="text-danger" />
            </span>
          </div>
        </div>
        <div class="mt-3">
          <span
            role="button"
            @click="addDrug"
            style="color: var(--main-color); gap: 4px"
            class="d-flex align-items-center"
          >
            <i-icon icon="lucide:plus-circle" />
            <span class="small">Add Drug</span>
          </span>
        </div>

        <hr />
        <h5 class="form-header mt-4 mb-2">Services Information</h5>
        <div
          class="d-flex align-items-center mt-2"
          v-for="(item, index) in serviceObject"
          :key="index"
          style="gap: 20px"
        >
          <div class="w-100">
            <label for="">Service Name</label>
            <el-select
              v-model="item.service"
              filterable
              placeholder="Enter Service"
              @change="getSingleService($event, index)"
            >
              <el-option
                v-for="(item, idx) in services"
                :key="idx"
                :label="item.service.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="w-25">
            <label for="">Duration</label>
            <el-input placeholder="" v-model="item.duration"></el-input>
          </div>
          <div class="w-25">
            <label for="">Rate</label>
            <el-input
              readonly
              disabled
              placeholder=""
              v-model="item.rate"
            ></el-input>
          </div>
          <div class="w-25">
            <label for="">Amount</label>
            <el-input
              readonly
              disabled
              placeholder=""
              v-model="item.amount"
            ></el-input>
          </div>
          <div class="">
            <span role="button" @click="removeService($event)">
              <i-icon icon="fluent:delete-24-filled" class="text-danger" />
            </span>
          </div>
        </div>
        <div class="mt-3">
          <span
            role="button"
            @click="addService"
            style="color: var(--main-color); gap: 4px"
            class="d-flex align-items-center"
          >
            <i-icon icon="lucide:plus-circle" />
            <span class="small">Add Service</span>
          </span>
        </div>
      </div>
      <div v-if="activeTab === 4">
        <h5 class="form-header mb-2">Remarks</h5>
        <div class="">
          <label for="">Remarks</label>
          <el-input type="textarea" v-model="remarks" rows="5"></el-input>
        </div>
      </div>
      <div class="d-flex justify-content-end mt-4">
        <div class="d-flex flex-row" style="gap: 20px">
          <button
            class="veo-btn veo-primary-btn"
            :class="{ 'tw-bg-gray-400': activeTab <= 1 }"
            :disabled="activeTab <= 1"
            @click="move('prev')"
          >
            Previous
          </button>
          <button class="veo-btn veo-primary-btn" @click="move('next')">
            {{ activeTab === headerItems.length ? "Finish" : "Next" }}
          </button>
        </div>
      </div>
    </div>

    <!-- Check Verification -->
    <VerifyEnrollee
      :visible="verifyUserModal"
      @verifyUser="verifyUser"
      :loading="isLoading"
      :error="verifyError"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import AddClaimsHeader from "@/components/Utils/ClaimsHeader.vue";

import VerifyEnrollee from "@/components/modals/VerifyEnrollee.vue";
export default {
  components: { VerifyEnrollee, AddClaimsHeader },
  data() {
    return {
      verifyUserModal: false,
      isLoading: false,
      verifyError: "",
      verifiedUser: null,

      activeTab: 1,
      verify: true,
      isVerified: false,
      headerItems: [
        {
          id: 1,
          title: "Enrollee & Provider Info.",
        },
        {
          id: 2,
          title: "Treatment Details",
        },
        {
          id: 3,
          title: "Drugs and Services Info.",
        },
        {
          id: 4,
          title: "Remarks",
        },
      ],
      drugObject: [],
      drug: {},
      serviceObject: [],
      service: {},
      care_type: "",
      doctor_name: "",
      area_of_speciality: "",
      date_of_service: "",
      duration_of_treatment: "",
      date_of_admission: "",
      date_of_discharge: "",
      diagnosis: "",
      treatment_given: "",
      referral_code: "",
      code: "",
      remarks: "",
      treatment_verification_code: "",
      company: "",
      gender: "",
      input: "",
      value: "",
      options: [
        {
          id: 1,
          value: "male",
          label: "Male",
        },
        {
          id: 2,
          value: "female",
          label: "Female",
        },
      ],
      careTypeList: [
        {
          id: 1,
          value: "primary",
          label: "Primary",
        },
        {
          id: 2,
          value: "secondary",
          label: "Secondary",
        },
      ],
    };
  },
  methods: {
    ...mapActions("claims", [
      "setDrugsPricesId",
      "setServicesPricesId",
      "addClaim",
      "setDrugsPrices",
      "setServicesPrices",
    ]),

    // Verify User
    async verifyUser(value) {
      this.isLoading = true;
      var dataObj = JSON.parse(value);
      try {
        const res = await this.$request.post("/hospitals/view/user", dataObj);
        this.isLoading = false;
        this.verifiedUser = res.data.data;
        console.log(this.verifiedUser);
        if (this.verifiedUser.can_access_healthcare === "1") {
          this.verifyUserModal = false;
          this.$swal.fire(
            "Enrollee Verified Successfully!",
            "However, Note that you require authorisation code for secondary  care  Services.",
            "success"
          );
        } else {
          this.verifyError = `This user with unique id ${dataObj.unique_id} cannot access healthcare.`;
        }

        return res;
      } catch (error) {
        this.isLoading = false;
        this.verifyError = "User verification failed!!! <br/> User cannot access healthcare from your facility";
        return error;
      }
    },

    move(value) {
      value === "next" ? this.next() : this.prev();
    },

    next() {
      if (this.activeTab === this.headerItems.length) {
        this.complete();
        console.log(this.payload);
      } else {
        this.activeTab++;
      }
    },

    prev() {
      this.activeTab--;
    },

    addDrug() {
      this.drugObject.push({
        name: null,
        unit: null,
        rate: null,
        amount: null,
      });
    },

    removeDrug(e) {
      if (this.drugObject.length !== 0) {
        this.drugObject.splice(e, 1);
      }
    },

    getSingleDrug(e, index) {
      this.setDrugsPricesId(e)
        .then((res) => {
          // this.drug = res;
          console.log(index, res);
          // console.log(this.drug);
          // console.log(index);
          const item = this.drugObject[index];

          console.log(item);
          const idd = this.drugObject.findIndex((i) => i.drug === item.drug);
          console.log(idd);
          // Find the index of the object you want to update
          // Update the object using Vue.set()
          Vue.set(this.drugObject, idd, {
            drug: res.id,
            id: String(res.drug_id),
            // product: res.pro,
            unit: "",
            rate: res.price,
            amount: "",
          });
          console.log(this.drugObject);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    addService() {
      this.serviceObject.push({
        drug: null,
        duration: null,
        rate: null,
        amount: null,
      });
    },

    removeService(e) {
      if (this.serviceObject.length !== 0) {
        this.serviceObject.splice(e, 1);
      }
    },

    getSingleService(e, index) {
      this.setServicesPricesId(e)
        .then((res) => {
          // this.drug = res;
          console.log(index, res);
          // console.log(this.drug);
          // console.log(index);
          const item = this.serviceObject[index];

          console.log(item);
          const idd = this.serviceObject.findIndex(
            (i) => i.service === item.service
          );
          console.log(idd);
          // Find the index of the object you want to update
          // Update the object using Vue.set()
          Vue.set(this.serviceObject, idd, {
            service: res.id,
            id: String(res.service_id),
            // product: res.pro,
            duration: "",
            rate: res.price,
            amount: "",
          });
          console.log(this.serviceObject);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    complete() {
      if (this.treatment_verification_code) {
        var treatment_codes = this.treatment_verification_code.split(",");
        console.log(treatment_codes);
      }

      var service = {};
      if (this.serviceObject.length > 0) {
        Object.keys(this.serviceObject[0]).forEach((key) => {
          service[key + "s"] = this.serviceObject.map((obj) => obj[key]);
        });
      }

      console.log(service);

      var drugs = {};
      if (this.drugObject.length > 0) {
        Object.keys(this.drugObject[0]).forEach((key) => {
          drugs[key + "s"] = this.drugObject.map((obj) => obj[key]);
        });
      }

      console.log(drugs);

      if (this.drugObject.length !== 0) {
        var drugTotal = drugs.amounts.reduce(function (x, y) {
          return Number(x) + Number(y);
        }, 0);
      }
      console.log(drugTotal);

      if (this.serviceObject.length !== 0) {
        var serviceTotal = service.amounts.reduce((accumulator, item) => {
          return accumulator + Number(item);
        }, 0);
      }
      console.log(serviceTotal);

      let totalPrice = Number(drugTotal) + Number(serviceTotal);
      console.log(totalPrice);
      let formData = new FormData();
      formData.append("user_id", this.verifiedUser.id);
      formData.append("hospital_id", this.hospital_id);
      formData.append("care_type", this.care_type);
      formData.append("total_amount", String(totalPrice));
      formData.append("drug", true);
      formData.append("service", true);
      for (const item of service.ids) {
        formData.append("service_id[]", item);
      }
      for (const item of service.durations) {
        formData.append("duration[]", item);
      }
      for (const item of service.rates) {
        formData.append("rate[]", item);
      }
      for (const item of service.amounts) {
        formData.append("amount[]", item);
      }

      formData.append("doctor_name", this.doctor_name);
      formData.append("area_of_speciality", this.area_of_speciality);
      formData.append("date_of_service", this.date_of_service);
      formData.append("duration_of_treatment", this.duration_of_treatment);
      formData.append("date_of_admission", this.date_of_admission);
      formData.append("date_of_discharge", this.date_of_discharge);
      formData.append("diagnosis", this.diagnosis);
      formData.append("treatment_given", this.treatment_given);
      for (const item of drugs.ids) {
        formData.append("drug_id[]", item);
      }
      for (const item of drugs.units) {
        formData.append("quantity[]", item);
      }
      for (const item of drugs.rates) {
        formData.append("rate_for_drug[]", item);
      }
      for (const item of drugs.amounts) {
        formData.append("amount_for_drug[]", item);
      }
      formData.append("code", this.code);
      formData.append("referral_code", this.referral_code);
      for (const item of this.treatment_verification_code) {
        formData.append("treatment_verification_code[]", item);
      }
      this.addClaim(formData);
    },
  },

  mounted() {
    this.verifyUserModal = true;
  },

  beforeMount() {
    this.setDrugsPrices();
    this.setServicesPrices();
  },

  watch: {
    drugObject: {
      handler: function (newVal) {
        // Find the item that has changed
        newVal.find(function (item) {
          item.amount = String(item.rate * item.unit);
        });
        // vall.unit = this.product === null ? "" : this.product.cost_price;
      },
      deep: true,
    },
    serviceObject: {
      handler: function (newVal) {
        // Find the item that has changed
        newVal.find(function (item) {
          item.amount = String(item.rate * item.duration);
        });
        // vall.unit = this.product === null ? "" : this.product.cost_price;
      },
      deep: true,
    },
  },

  computed: {
    ...mapState("claims", {
      services: (state) => state.service_prices,
      drugPrice: (state) => state.drug_price,
      drugs: (state) => state.drug_prices,
      servicePrice: (state) => state.service_price,
    }),

    ...mapState("auth", {
      hospital_id: (state) => state.user.id,
    }),

    userName() {
      return this.verifiedUser.first_name + " " + this.verifiedUser.last_name;
    },
  },
};
</script>

<style scoped>
.form-container {
  border: 2px solid var(--accent-bg);
  padding: 30px;
  border-radius: 5px;
}

label {
  font-size: 13px;
}

input,
select,
textarea {
  display: block;
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid var(--gray-400);
  outline: none;
  font-size: 14px;
}

.el-input__inner,
.el-textarea__inner {
  width: 100% !important;
  font-size: 14px !important;
}

.el-input__inner:focus,
.el-textarea__inner:focus {
  border-color: var(--main-color) !important;
}

.form-header {
  font-size: 17px;
  font-weight: 600;
  color: var(--primary);
}

.d-flex div {
  display: flex;
  display: inline-flex;
  display: -webkit-flex;
  flex-direction: column;
  gap: 4px;
}
</style>
