<template>
  <div>
    <div>
      <div class="tw-bg-primary tw-rounded-lg tw-p-4 tw-mt-8 tw-relative">
        <h4 class="tw-mb-0 tw-text-white">
          <span class="tw-text-[16px] tw-text-accent">Welcome,</span>
          {{ hospitalName.name }}
        </h4>
        <p class="tw-mb-0 tw-text-white tw-text-xs">
          You can access your enrollees, claims, invoices and all other
          information
        </p>
        <div class="tw-absolute -tw-top-16 tw-right-0">
          <img
            src="@/assets/img/doctors.png"
            width="200px"
            height="130px"
            alt=""
          />
        </div>
      </div>
      <div class="tw-grid tw-grid-cols-4 tw-gap-8 tw-mt-8">
        <div
          style="background: #2a9871"
          class="tw-flex tw-flex-col tw-p-3 tw-rounded-lg tw-gap-2"
        >
          <div class="d-flex align-items-end justify-content-between">
            <div>
              <h2 class="tw-text-white tw-mb-0 tw-font-bold">{{ due_invoices.length }}</h2>
              <h6 class="tw-text-accent tw-font-light tw-text-sm">Due Invoices</h6>
            </div>
            <div>
              <i-icon icon="bi:people-fill" class="icon" />
            </div>
          </div>
          <div class="view-more-section">
            <router-link to="/dashboard/invoices"><button>View</button></router-link>
          </div>
        </div>
        <div
          style="background: #e22b1f"
          class="tw-flex tw-flex-col tw-p-3 tw-rounded-lg tw-gap-2"
        >
          <div class="d-flex align-items-end justify-content-between">
            <div>
              <h2 class="tw-text-white tw-mb-0 tw-font-bold">{{ invoices.length }}</h2>
              <h6 class="tw-text-accent tw-font-light tw-text-sm">Submitted Invoices</h6>
            </div>
            <div>
              <i-icon
                icon="fa6-solid:money-bill-transfer"
                class="icon"
              />
            </div>
          </div>
          <div class="view-more-section">
            <router-link to="/dashboard/invoices"><button>View</button></router-link>
          </div>
        </div>
        <div
          style="background: #1f4ae2"
          class="tw-flex tw-flex-col tw-p-3 tw-rounded-lg tw-gap-2"
        >
          <div class="d-flex align-items-end justify-content-between">
            <div>
              <h2 class="tw-text-white tw-mb-0 tw-font-bold">{{ claims.length }}</h2>
              <h6 class="tw-text-accent tw-font-light tw-text-sm">Claims</h6>
            </div>
            <div>
              <i-icon icon="bi:people-fill" class="icon" />
            </div>
          </div>
          <div class="view-more-section">
            <router-link to="/dashboard/claims"><button>View</button></router-link>
          </div>
        </div>
        <div
          style="background: #daa00c"
          class="tw-flex tw-flex-col tw-p-3 tw-rounded-lg tw-gap-2"
        >
          <div class="d-flex align-items-end justify-content-between">
            <div>
              <h2 class="tw-text-white tw-mb-0 tw-font-bold">{{ enrollees.length }}</h2>
              <h6 class="tw-text-accent tw-font-light tw-text-sm">Enrollees</h6>
            </div>
            <div>
              <i-icon icon="bi:people-fill" class="icon" />
            </div>
          </div>
          <div class="view-more-section">
            <router-link to="/dashboard/enrollees"><button>View</button></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      claims: [],
      due_invoices: [],
      enrollees: [],
      invoices: [],
    };
  },
  methods: {
    ...mapActions("user", ["getEnrollees"]),
    ...mapActions("claims", ["getInvoices", "getClaims"]),
    getInvoices() {
      this.busy = true;
      this.$request
        .get("/hospitals/invoices")
        .then((res) => {
          console.log(res, "submitted");
          let resPayload = res.data.data;
          this.invoices = resPayload;
          this.busy = false;
        })
        .catch((err) => {
          this.busy = false;
          console.log(err);
        });
    },

    getClaims() {
      this.busy = true;
      this.$request
        .get("/hospitals/claims")
        .then((res) => {
          let resPayload = res.data.data;
          this.claims = resPayload;
          this.busy = false;
        })
        .catch((err) => {
          this.busy = false;
          console.log(err);
        });
    },

    getUnsubmitedClaims() {
      this.busy = true;
      this.$request
        .get("/hospitals/claims")
        .then((res) => {
          console.log(res, "unsubmitted");
          let resPayload = res.data.data;
          this.due_invoices = resPayload.filter(
            (elem) => elem.is_submitted !== "1"
          );
          this.busy = false;
        })
        .catch((err) => {
          this.busy = false;
          console.log(err);
        });
    },

    getEnrollees() {
      this.busy = true;
      this.$request
        .get("/hospitals/enrollees/all")
        .then((res) => {
          console.log(res);
          let resPayload = res.data.data;
          this.enrollees = resPayload;
          this.busy = false;
        })
        .catch((err) => {
          this.busy = false;
          console.log(err);
        });
    },
  },
  beforeMount() {
    this.getEnrollees();
    this.getInvoices();
    this.getClaims();
    this.getUnsubmitedClaims();
  },
  computed: {
    hospitalName() {
      return this.$store.getters["auth/getUser"];
    },
  },
};
</script>

<style>
.view-more-section a {
  background: #fff;
  padding: 5px 12px;
  border-radius: 5px;
  font-size: 13px;
  border: none;
  text-align: center;
  color: #000 !important;
  font-weight: 500;
}
</style>
