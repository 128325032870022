<template>
    <div class="">
      <div class="items-list">
        <div v-for="item in headerItems" :key="item.id">
          <div class="d-flex items" style="gap: 2px">
            <span v-text="item.id + '.'"></span>
            <span v-text="item.title"></span>
          </div>
          <span
            class="status-bar"
            :class="{ 'active-bar': item.id <= activeTab }"
          ></span>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
      props: {
          headerItems: {
              type: Array
          },
          activeTab: {
              type: Number
          }
      },
    data() {
      return {
        
      };
    },
  };
  </script>
  
  <style>
  .items-list {
    display: grid;
    display: -ms-grid;
    display: -moz-grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
  }
  
  .items {
    font-size: 13px;
  }
  
  .status-bar {
    display: block;
    height: 4px;
    width: 100%;
    background: var(--light);
  }
  
  .status-bar.active-bar {
    background: var(--primary);
  }
  </style>
  