import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";

import "@/assets/css/main.css";

import "toastify-js/src/toastify.css";
import toastify from "toastify-js";

import { Icon } from "@iconify/vue2";

Vue.component("i-icon", Icon);

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(ElementUI);

import VueStepWizard from "vue-step-wizard";
import "vue-step-wizard/dist/vue-step-wizard.css";
Vue.use(VueStepWizard);

import vSelect from "vue-select";

Vue.component("v-select", vSelect);

import "vue-select/dist/vue-select.css";

//global registration
import VueFormWizard from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
Vue.use(VueFormWizard);

Vue.config.ignoredElements = [/^Icon-/];


import instance from "@/config/https.js";
Vue.prototype.$request = instance;


Vue.prototype.$toastify = toastify;
// Vue.use(http)

Vue.config.ignoredElements = [/^ion-/];

Vue.config.productionTip = false;

// Filters
Vue.filter("displayDate", function (value) {
  if (value) {
    return moment(String(value)).format("DD MMMM YYYY");
  }
});

Vue.filter("displayTime", function (value) {
  if (value) {
    return moment(String(value)).format("hh:mm A");
  }
});

Vue.filter("strLimit", function (value) {
  if (value) {
    return value.slice(0, 40) + "...";
  }
});

Vue.filter("createRef", function (value) {
  if (value) {
    return ("#" + value.slice(0, 5)).toUpperCase();
  }
});

import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/en.json";

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule],
    message: messages[rule], 
  });
});

Vue.component("validation-provider", ValidationProvider);
Vue.component("validation-observer", ValidationObserver);


// Plugins
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);

// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);


// vue-sweetalert2
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2, {
  confirmButtonColor: "#41b882",
  cancelButtonColor: "#ff7674",
  reverseButtons: false,
  buttonsStyling: true,
  customClass: {
    confirmButton: "tw-bg-green-600 tw-text-white tw-py-3",
    cancelButton: "tw-bg-red-600 tw-text-white tw-py-3",
  },
});

new Vue({
  router,
  store,
  toastify,
  render: (h) => h(App),
}).$mount("#app");
