import Dashboard from "@/modules/Dashboard/IndexPage.vue";
import Enrollees from "@/modules/Dashboard/Enrollees/IndexPage.vue"
import Invoices from "@/modules/Dashboard/InvoicesPage.vue"
import viewClaimsVue from "@/modules/Dashboard/Claims/IndexPage.vue";
import AddClaim from "@/modules/Dashboard/Claims/AddClaim.vue";
import VerifyEnrollee from "@/modules/Dashboard/VerifyEnrolee.vue";
import HMOInformation from "@/modules/Dashboard/HMOInformation.vue";
import CapitationList from "@/modules/Dashboard/CapitationList.vue";
import PricingPageVue from "@/modules/Dashboard/PricingPage.vue";

const router = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      layout: "DashboardLayout",
      parent: "dashboard",
      name: "dashboard",
      header: "dashboard",
      description: "Analytics of hospital user data",
      requiresAuth: true
    },
  },

  {
    path: "/dashboard/enrollees",
    name: "enrollees",
    component: Enrollees,
    meta: {
      layout: "DashboardLayout",
      parent: "enrollees",
      name: "enrollees",
      header: "enrollees",
      description: "All enrollees registered to your hospital",
      requiresAuth: true
    },
  },

  {
    path: "/dashboard/invoices",
    name: "invoices",
    component: Invoices,
    meta: {
      layout: "DashboardLayout",
      parent: "invoices",
      name: "invoices",
      header: "invoices",
      description: "All pending or submitted invoices made from claims",
      requiresAuth: true
    },
  },

  {
    path: "/dashboard/claims",
    name: "claims",
    component: viewClaimsVue,
    meta: {
      layout: "DashboardLayout",
      parent: "claims",
      name: "claims",
      header: "claims",
      description: "View Claims",
      requiresAuth: true
    },
  },

  {
    path: "/dashboard/claim/create",
    name: "create-claim",
    component: AddClaim,
    meta: {
      layout: "DashboardLayout",
      parent: "claims",
      name: "create claim",
      header: "Create New Claim",
      description: "Create a New Claim",
      requiresAuth: true
    },
  },

  {
    path: "/dashboard/verify-enrollee",
    name: "create-claim",
    component: VerifyEnrollee,
    meta: {
      layout: "DashboardLayout",
      parent: "verify-enrollee",
      name: "Verify Enrollee",
      header: "Verify Enrollee",
      description: "View Verified Enrollee details",
      requiresAuth: true
    },
  },

  {
    path: "/dashboard/capitation-list",
    name: "capitation-list",
    component: CapitationList,
    meta: {
      layout: "DashboardLayout",
      parent: "capitation-list",
      name: "Capitation List",
      header: "Capitaltion List",
      description: "Detais coming Soon",
      requiresAuth: true
    },
  },

  {
    path: "/dashboard/hmo-information",
    name: "hmo-information",
    component: HMOInformation,
    meta: {
      layout: "DashboardLayout",
      parent: "hmo-information",
      name: "HMO Information",
      header: "HMO Information",
      description: "View Information about this HMO",
      requiresAuth: true
    },
  },

  {
    path: "/dashboard/prices",
    name: "prices",
    component: PricingPageVue,
    meta: {
      layout: "DashboardLayout",
      parent: "prices",
      name: "Prices",
      header: "Prices",
      description: "View prices added for your hospital",
      requiresAuth: true
    },
  },
];

export default router;
