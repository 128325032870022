<template>
  <div>
    <div class="main-content my-5" v-if="verifiedUser">
      <div class="info-card">
        <img
          :src="
            verifiedUser.profile_picture !== null
              ? config.apiUrl + '/' + verifiedUser.profile_picture
              : ''
          "
          alt="user Profile Photo"
          style="
            height: 80px;
            width: 80px;
            object-fit: cover;
            object-position: center;
            border-radius: 50%;
          "
        />
        <h5 class="font-weight-bold mt-3">
          {{ verifiedUser.first_name + " " + verifiedUser.last_name }}
        </h5>
        <small class="text-secondary">{{ verifiedUser.email }}</small>

        <div class="mt-3">
          <ul class="m-0 tabs">
            <li v-for="item in tabs" :key="item.id">
              <span
                role="button"
                :class="{ 'active-tab': item.id === activeTab }"
                @click="switchTab(item.id)"
                >{{ item.title }}</span
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="">
        <personal-information :info="verifiedUser" v-if="activeTab === 1" />
        <plan-details :info="verifiedUser" v-if="activeTab === 3" />
        <user-dependents :info="verifiedUser" v-if="activeTab === 2" />
      </div>
    </div>
    <VerifyEnrollee :visible="verifyUserModal"
      @verifyUser="verifyUser"
      :loading="isLoading"
      :error="verifyError" />
  </div>
</template>

<script>
import config from "@/config/api";
import VerifyEnrollee from "@/components/modals/VerifyEnrollee.vue";
// import { mapState } from "vuex";
import PersonalInformation from "@/components/Utils/PersonalInformation.vue";
import PlanDetails from "@/components/Utils/PlanDetails.vue";
import UserDependents from "@/components/Utils/UserDependents.vue";
export default {
  components: {
    PlanDetails,
    PersonalInformation,
    UserDependents,
    VerifyEnrollee,
  },
  data() {
    return {
      config,
      verify: true,
      isVerified: false,
      activeTab: 1,
      verifyUserModal: false,
      isLoading: false,
      verifyError: "",
      verifiedUser: null,
      tabs: [
        {
          id: 1,
          title: "Personal Information",
        },
        {
          id: 2,
          title: "Dependents",
        },
      ],
    };
  },
  methods: {
    closeVerify() {
      this.verify = false;
    },
    close() {
      this.verify = false;
      this.isVerified = true;
    },
    closeVerification() {
      this.isVerified = false;
    },
    switchTab(value) {
      this.activeTab = value;
    },

    // Verify User
    async verifyUser(value) {
      this.isLoading = true;
      var dataObj = JSON.parse(value);
      try {
        const res = await this.$request.post("/hospitals/view/user", dataObj);
        this.isLoading = false;
        this.verifiedUser = res.data.data;
        this.verifyUserModal = false;
        this.$swal.fire("Woo hoo!", "Enrollee verified Succesfully", "success");
        return res;
      } catch (error) {
        this.isLoading = false;
        this.verifyError = "User verification failed!!!";
        return error;
      }
    },
  },

  beforeMount() {
    this.$store.commit("claims/REMOVE_VERIFIED_USER");
  },

  mounted() {
    this.verifyUserModal = true;
  },

  computed: {
    // ...mapState("claims", {
    //   verifiedUser: (state) => state.verifiedUser,
    // }),

    userName() {
      return this.verifiedUser.first_name + " " + this.verifiedUser.last_name;
    },
  },
};
</script>

<style>
.tabs {
  display: flex;
  display: inline-flex;
  display: -webkit-flex;
  flex-direction: column;
  gap: 12px;
}

.tabs li span {
  font-size: 15px;
  font-size: 500;
  padding: 6px 0;
  display: block;
}

.main-content {
  display: grid;
  display: -ms-grid;
  display: -moz-grid;
  grid-template-columns: 30% 1fr;
  gap: 25px;
  align-items: start;
}

.active-tab {
  color: var(--primary);
  font-weight: 500;
  position: relative;
}

.active-tab::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background: var(--primary);
  height: 2px;
  width: 50%;
}
</style>
