import Vue from 'vue'
import VueRouter from 'vue-router'

import Auth from "./modules/auth"
import Dashboard from "./modules/dashboard"

// Page Not Found 
import PageNotFound from "@/views/ErrorPage.vue"

Vue.use(VueRouter)

const routes = [

  // Route for Page Not found 
  { path: "*", component: PageNotFound },
  
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes.concat(Auth, Dashboard),
    scrollBehavior () {
        return window.scrollTo({ top: 0, behavior: 'smooth' })
      }
})

router.beforeEach((to, from, next) => {
    var isLoggedIn = localStorage.getItem('token');
    // Check if the route requires authentication
    if (to.matched.some(record => record.meta.requiresAuth)) {
      // Check if the user is authenticated
      if (!isLoggedIn) {
        // Redirect to the login page
        next({ path: '/sign-in', query: { redirectFrom: to.fullPath } })
      } else {
        next()
      }
    } else {
      next()
    }
  })

export default router