<template>
  <div>
    <h4 class="header-text">Dependents</h4>
    <p class="tag-info">View user dependents</p>
    <div v-if="info.family.dependents.length === 0">
      <el-empty
        :image-size="200"
        description="No dependents for this Enrollee"
      ></el-empty>
    </div>
    <div class="info-container mt-3">
      <div
        class="info-card"
        v-for="item in info.family.dependents"
        :key="item.id"
      >
        <div>
          <span class="info-card-header">
            <span class="info-card-header-text">Name</span>
            <span class="info-card-icon">
              <!-- <i-icon icon="ph:user-circle" /> -->
              <img
                :src="
                  item.profile_picture !== null
                    ? config.apiUrl + '/' + item.profile_picture
                    : ''
                "
                alt="user Profile Photo"
                style="
                  height: 30px;
                  width: 30px;
                  object-fit: cover;
                  object-position: center;
                  border-radius: 50%;
                "
              />
            </span>
          </span>
          <span class="info-card-data">
            {{
              item.first_name +
              " " +
              item.last_name +
              " " +
              (item.other_name || "")
            }}
          </span>
        </div>
        <hr class="my-0" />
        <div>
          <span class="info-card-header">
            <span class="info-card-header-text">Relationship</span>
            <span class="info-card-icon">
              <i-icon icon="carbon:chart-relationship" />
            </span>
          </span>
          <span class="info-card-data">
            {{ item.relationship }}
          </span>
        </div>
        <hr class="my-0" />
        <div>
          <span class="info-card-header">
            <span class="info-card-header-text">Gender</span>
            <span class="info-card-icon">
              <i-icon icon="icons8:gender" />
            </span>
          </span>
          <span class="info-card-data">
            {{ item.gender }}
          </span>
        </div>
        <hr class="my-0" />
        <div>
          <span class="info-card-header">
            <span class="info-card-header-text">Date of Birth</span>
            <span class="info-card-icon">
              <i-icon icon="uiw:date" />
            </span>
          </span>
          <span class="info-card-data">
            {{ item.dob }}
          </span>
        </div>
        <hr class="my-0" />
        <div>
          <span class="info-card-header">
            <span class="info-card-header-text">Address</span>
            <span class="info-card-icon">
              <i-icon icon="pajamas:location" />
            </span>
          </span>
          <span class="info-card-data">
            {{ item.address }}
          </span>
        </div>
      </div>
    </div>
    <!-- {{ info }} -->
  </div>
</template>

<script>
import config from "@/config/api";
// import { mapState } from "vuex";
export default {
  props: {
    info: {
      type: Object
    }
  },
  data() {
    return {
      config,
    };
  },
  computed: {
    // ...mapState("claims", {
    //   info: (state) => state.verifiedUser,
    // }),
  },
};
</script>

<style>
.info-container {
  display: grid;
  display: -ms-grid;
  display: -moz-grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.info-card {
  background: #fff;
  padding: 18px;
  border-radius: 15px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.125);
  display: flex;
  display: inline-flex;
  display: -webkit-flex;
  flex-direction: column;
  gap: 6px;
}
.tag-info {
  font-size: 14px;
  color: var(--gray-600);
  font-weight: 400;
}
.header-text {
  font-weight: 600;
}
.info-card-header-text {
  font-size: 15px;
  font-weight: 600;
}
.info-card-data {
  font-size: 14px;
  color: var(--gray-600);
}
.info-card-header {
  display: flex;
  display: inline-flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
}
.info-card-header .info-card-icon {
  color: var(--main-color);
}
</style>
