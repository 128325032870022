// import Vue from 'vue';
import $request from '@/config/https'
// import Axios from '@/plugins/axios'
import createPersistedState from 'vuex-persistedstate';

// ES6 Modules or TypeScript
import Swal from 'sweetalert2'

// import Swal from 'sweetalert2/dist/sweetalert2.js'

// import 'sweetalert2/src/sweetalert2.scss'

import "toastify-js/src/toastify.css"
import router from '@/router';

const getDefaultState = () => {
    return {
        verifiedUser: null,
        is_verified: false,
        drug_prices: [],
        service_prices: [],
        drug_price: {},
        service_price: {},
        loading: false,

        // Added Drugs
        added_drugs: [],

        // Claims - Created from Visits  
        claims: [],

        // Invoices - Created from claims
        invoices: []
    };
};

export default {
    namespaced: true,
    strict: true,
    plugins: [createPersistedState()],
    state: getDefaultState(),
    getters: {

        verifiedUser: state => {
            return state.verifiedUser;
        },
        isVerified: state => {
            return state.is_verified;
        },
        drugPrices: state => {
            return state.drug_prices;
        },
        servicePrices: state => {
            return state.service_prices;
        },
        drugPrice: state => {
            return state.drug_price;
        },
        servicePrice: state => {
            return state.service_price;
        },
        getClaims: state => {
            return state.claims
        },
        addedDrugs: state => {
            return state.added_drugs
        },
        getInvoices: state => {
            return state.invoices
        }
    },
    mutations: {
        SET_VERIFIED_USER: (state, verifiedUser) => {
            state.verifiedUser = verifiedUser;
            state.loading = false
        },
        REMOVE_VERIFIED_USER: (state) => {
            state.verifiedUser = null;
        },
        SET_DRUG_PRICES: (state, drug_prices) => {
            state.drug_prices = drug_prices;
            state.loading = false
        },
        SET_SERVICES_PRICES: (state, service_prices) => {
            state.service_prices = service_prices;
            state.loading = false
        },
        SET_VERIFIED_STATUS: (state, is_verified) => {
            state.is_verified = is_verified;
            state.loading = false
        },
        SET_DRUG_PRICE: (state, drug_price) => {
            state.drug_price = drug_price;
        },
        ADD_DRUG: (state, drug) => {
            state.added_drugs.push(drug);
        },
        SET_SERVICE_PRICE: (state, service_price) => {
            state.service_price = service_price;
            state.loading = false
        },
        SET_CLAIMS: (state, claims) => {
            state.claims = claims
            state.loading = false
        },
        SET_INVOICES: (state, data) => {
            state.invoices = data
            state.loading = false
        },
        SET_LOADING_STATUS: (state, data) => {
            state.loading = data
        }
    },
    actions: {
        // Check Verified User
        async checkVerified({ commit }, data) {
            commit('SET_VERIFIED_USER', data);
        },

        // Get Drugs Prices
        async setDrugsPrices({ commit }) {
            try {
                const res = await $request.get('/hospitals/drugs/price/all')
                commit('SET_DRUG_PRICES', res.data.data);
                return res.data.data;
            } catch (error) {
                return error;
            }
        },

        // Get Drugs By Id
        async setDrugsPricesId({ commit }, id) {
            try {
                const res = await $request.get(`/hospitals/drug/price/view/${id}`)
                commit('SET_DRUG_PRICE', res.data.data);
                return res.data.data;
            } catch (error) {
                return error;
            }
        },


        // Get Services Prices
        async setServicesPrices({ commit }) {
            try {
                const res = await $request.get('/hospitals/service/price/all')
                commit('SET_SERVICES_PRICES', res.data.data);
                console.log(res.data.data);
                return res.data.data;
            } catch (error) {
                return error;
            }
        },
        // Get Services By Id
        async setServicesPricesId({ commit }, id) {
            try {
                const res = await $request.get(`/hospitals/service/price/view/${id}`)
                commit('SET_SERVICE_PRICE', res.data.data);
                console.log(res.data.data);
                return res.data.data
            } catch (error) {
                return error;
            }
        },


        // Claims 
        // Add Claims
        async addClaim({ dispatch, commit }, payload) {
            commit('SET_LOADING_STATUS', true)
            try {
                const res = await $request.post(`/hospitals/claims/add/`, payload)
                dispatch('getClaims');
                Swal.fire({
                    title: 'Claim Added!',
                    text: "You have added a new claim!",
                    icon: 'success',
                    confirmButtonText: 'View all Claims!'
                  }).then((result) => {
                    if (result.isConfirmed) {
                      router.push('/dashboard/claims')
                    }
                  })
                // router.push("/new-visit")
                return res.data
            } catch (error) {
                Swal.fire({
                    title: 'Claim Not Added!',
                    text: "Your attempt to add a claim was not succesful, try again!",
                    icon: 'warning',
                    confirmButtonText: 'Ok!'
                  })
                  commit('SET_LOADING_STATUS', false)
                return error;
            }
        },

    }
}